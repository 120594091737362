import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.';

interface State {
    isOpen: boolean;
    content: string;
}

const initialState: State = {
    isOpen: false,
    content: ""
};

export const citationViewerDrawerSlice = createSlice({
    name: 'citationViewerDrawer',
    initialState,
    reducers: {
        openCitationViewerDrawer: (state) => {
            state.isOpen = true;
        },
        closeCitationViewerDrawer: (state) => {
            state.isOpen = false;
        },
        setCitationDrawerContent: (state, action: PayloadAction<any>) => {
            state.content = action.payload;
        }
    },
})

export const { openCitationViewerDrawer, closeCitationViewerDrawer, setCitationDrawerContent } = citationViewerDrawerSlice.actions;

export const selectIsCitationViewerDrawerOpen = (state: RootState) => state.citationViewerDrawer.isOpen;
export const selectCitationDrawerContent = (state: RootState) => state.citationViewerDrawer.content;

export default citationViewerDrawerSlice.reducer;