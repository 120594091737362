import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { RouterProvider } from "react-router-dom";

import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

import "./scss/index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

async function loadLocaleData(locale: string) {
  const response = await fetch(`/lang/${locale}.json`);
  if (!response.ok) {
    throw new Error("Failed to load locale data");
  }
  const messages: any = await response.json();
  for (const key of Object.keys(messages)) {
    if (typeof messages[key] !== "string") {
      messages[key] = messages[key].defaultMessage;
    }
  }
  return messages;
}

async function bootstrapApplication() {
  console.log("fetching chat session");
  const data = await fetch("/chatapi/session");
  const session = await data.json();
  if (!session.authenticated) {
    console.log("couldn't authenticate");
    window.location.href = "/chatapi/login";
  } else {
    console.log("data fetched");
    sessionStorage.setItem("userID", session.userID);

    // Dynamically import router after authentication
    const { router } = await import("./router");
    const locale = navigator.language;

    let messages: any;
    try {
      messages = await loadLocaleData(locale.toLocaleLowerCase());
    } catch (e) {
      console.warn("No locale data for", locale);
    }

    root.render(
      <React.StrictMode>
        <IntlProvider locale={navigator.language} defaultLocale="en-GB">
          <MantineProvider theme={{ colorScheme: "dark" }}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ModalsProvider>
                  <RouterProvider router={router} />
                </ModalsProvider>
              </PersistGate>
            </Provider>
          </MantineProvider>
        </IntlProvider>
      </React.StrictMode>
    );
  }
}

bootstrapApplication();
